// require('offline-plugin/runtime').install();
import 'loading-attribute-polyfill';
import $ from 'jquery';
// import 'bootstrap/js/src/popover.js'
// import 'bootstrap/js/src/scrollspy.js'
// import 'bootstrap/js/src/toast.js'
// import 'bootstrap/js/src/tooltip.js'
// import 'bootstrap/js/src/button.js'
// import 'bootstrap/js/src/modal.js'
// import 'bootstrap/js/src/util.js'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import './404.html';
import './index.html';
import './styles/index.scss';
import './scripts/script.js';

const version = '1.2.5';

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})
